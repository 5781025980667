export const pxToRem = (pxSize, rootSize = 18) => {
  return (pxSize / rootSize).toFixed(2)
}

export const rem = pxSize => {
  return `${pxToRem(pxSize)}rem`
}

// spacing

// 64
// 120

export const spacePx = [
  0,
  8, //
  16,
  24,
  32,
  40,
  48,
  56,
  64,
  72,
  80,
  88,
  96,
  104,
  112,
  120,
  128,
]
export const space = spacePx.map(i => rem(i))
export const fontSizePx = [16, 18, 24, 26, 48, 56, 76]
export const fontSizes = fontSizePx.map(i => rem(i))

const theme = {
  // Fonts
  fonts: {
    body: "'Din', sans-serif",
    heading: "'Futura'",
  },
  // Colors
  colors: {
    // general
    text: "#3A2A55",
    grey: "#E0E0E0",
    lightGrey: "#EEEEEE",
    white: "#FFFFFF",
    black: "#000000",
    pink: "#FFCCD8",
    blue: "#8ED6F0",
    red: "#F37361",
    yellow: "#FCDF5C",
    violet: "#D8D2FB",
    darkPurple: "#3A2A55",
    turquoise: "#5BC7BE",
    darkBlue: "#05285C",
    lime: "#C5FFC1",
    'neonBlue': '#70E6DD',
    // core
    purpleLight: "#665191",
    purple: "#6A5095",
    corePink: "#FFD1DC",
    yellow: '#F7E072',
    // rainbow
    rainbowOrange: "#F89B3C",
    rainbowGreen: "#A3BB4A",
    rainbowRed: "#F37361",
    // trans
    transBlue: "#4EC9F5",
    transPink: "#F391BC",
    // text-specific
  },
  colorSets: {
    purplePink: {
      backgroundColor: "purple",
      color: "pink",
    },
    turquoiseDarkPurple: {
      backgroundColor: "turquoise",
      color: "pink",
    },
    darkPurpleWhite: {
      backgroundColor: "darkPurple",
      color: "white",
    },
  },
  spacing: {
    mediumY: {
      py: [rem(56)],
      px: ["5vw", null, rem(56)],
    },
    smallY: {
      py: [rem(36)],
    },
  },
  padding: {
    large: {
      padding: ["5vw"],
      margin: 0,
    },
    medium: {
      padding: ["4vw"],
      margin: 0,
    },
    small: {
      padding: ["4vw", "2.5vw"],
      margin: 0,
    },
  },
  typography: {
    link: {
      fontFamily: "heading",
      textTransform: "uppercase",
      fontSize: [rem(16), null, null, rem(22)],
      color: "darkPurple",
      fontWeight: 900,
    },
    linkInline: {
      textDecoration: "underline",
      color: "inherit",
      fontWeight: 600,
      "&:hover": {
        color: "#6A5095",
      },
    },
    heading: {
      fontFamily: "heading",
      textTransform: "uppercase",
      color: "darkPurple",
      fontWeight: 900,
    },
    sizing: {
      bodyCopy: {
        fontSize: [rem(14), null, null, rem(20)],
      },
      bodyCopyLarge: {
        fontSize: [rem(16), null, null, rem(22)],
      },
    },
  },
  buttons: {
    primary: {
      border: "none",
      px: rem(22),
      pt: rem(18),
      pb: rem(16),
      fontFamily: "heading",
      fontWeight: 900,
      fontSize: [rem(16), null, rem(18), rem(20)],
      textTransform: "uppercase",
      cursor: "pointer",
    },
    link: {
      display: "inline-flex",
      justifyContent: "center",
      alignItems: "center",
      color: "darkPurple",
      textDecoration: "underline",
      textUnderlineOffset: "2px",
      cursor: "pointer",
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
  // Sizing and spacing
  sizes: {
    container: 1500,
  },
  breakpoints: [
    '375px',
    '768px',
    '1024px',
    '1280px'


    // "40em", // 0
    // "52em", // 1
    // "64em", // 2
    // "80em", // 3
  ],
  space,

  fontSizes,
  fontWeights: {
    body: 500,
    heading: 900,
  },
  // Text variants
  text: {
    caps: {
      textTransform: "uppercase",
      letterSpacing: "0.2em",
    },
  },
  images: {
    productImageListItem: {
      width: 112,
      height: 112,
      objectFit: "cover",
    },
  },
  //Misc
  styles: {
    h1: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: 0.95,
      fontSize: [rem(44), rem(64), rem(72), rem(76)],
      textTransform: "uppercase",
    },
    h2: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: 1,
      fontSize: [rem(34), rem(42), rem(50)],
      textTransform: "uppercase",
    },
    h3: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: 1,
      fontSize: [rem(22), rem(32), rem(38)],
      textTransform: "uppercase",
      letterSpacing: '-.5px'
    },
    h4: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: 1,
      fontSize: [rem(18), rem(22), rem(26)],
      textTransform: "uppercase",
      letterSpacing: '-.5px'
    },
    h5: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: 0.95,
      fontSize: [rem(16), null, null, rem(22)],
      textTransform: "uppercase",
    },
    h6: {
      fontFamily: "heading",
      fontWeight: "heading",
      lineHeight: 0.95,
      fontSize: [rem(16), null, null, rem(20)],
      textTransform: "uppercase",
    },
    p: {
      color: "darkPurple",
      fontFamily: "body",
      lineHeight: 1.6,
      fontSize: [rem(16), null, null, rem(20)],
    },
  },
}

export const articleStyles = {
  ...theme.styles,
  pb: theme.space[5],
  "h1, h2, h3, h4, h5, h6": {
    marginBottom: rem(24),
    lineHeight: 1.1,
    fontWeight: 900,
  },
  a: {
    color: theme.colors.darkPurple,
    "&:hover": {
      color: theme.colors.purple,
    },
  },
  "ul, ol": {
    margin: 0,
    marginLeft: rem(20),
    padding: 0,
    li: {
      lineHeight: 1.5,
    },
  },
  p: {
    marginBottom: theme.space[4],
    lineHeight: 1.5,
  },
  "iframe, img": {
    width: "100%",
  },
}

export default theme

// buttons need to match link size
