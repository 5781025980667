/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import { rem } from "@src/theme"
import Cross from "@components/assets/svg/cross.svg"
import { Themed } from '@theme-ui/mdx'

const LineItem = ({
  item,
  onRemove,
  quantityValue,
  onQuantityChange,
  onQuantityBlur,
  limit,
  totalQty
}) => {
  if (item.variant === null || item.variant === undefined) {
    return null
  }
  const handleRemove = () => {
    onRemove(item.id, item.variant.id, item.variant.product.id)
  }
  return (
    <div
      sx={{
        py: 3,
        borderBottom: `2px solid rgba(58, 42, 85, 0.3)`,
      }}
    >
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "start",
        }}
      >
        <div
          sx={{
            display: "flex",
            flexDirection: ["row"],
            width: ["100%", rem(350)],
          }}
        >
          {item.variant.image && (
            <div
              sx={{
                position: "relative",
              }}
            >
              <button
                sx={{
                  position: "absolute",
                  borderRadius: "50%",
                  height: rem(22),
                  width: rem(22),
                  bg: "purple",
                  color: "white",
                  top: "-8px",
                  right: "-8px",
                  cursor: "pointer",
                }}
                aria-label="Remove item"
                onClick={handleRemove}
              >
                <Cross width={rem(10)} fill="white" color="white" />
              </button>
              <img
                src={item.variant.image.src}
                alt={item.title}
                sx={{
                  height: [rem(111)],
                  width: [rem(111)],
                  objectFit: "cover",
                }}
              />
            </div>
          )}
          <div
            sx={{
              ml: rem(20),
            }}
          >
            <p
              sx={{
                mt: 0,
                maxWidth: "100%",
                fontSize: [rem(14), rem(18)],
              }}
            >
              <Themed.h6
                sx={{
                  display: ["block", "none"],
                  mt: 0,
                  my: rem(10),
                }}
              >
                ${(item.variant.price.amount * item.quantity).toFixed(2)}
              </Themed.h6>
              {item.title}{" "}{totalQty}{limit ? `/${limit}` : ''}
              {item.variant.title !== "Default Title" &&
                `/ ${item.variant.title}`}
            </p>

            <div
              sx={{
                display: "flex",
                alignItems: "center",
                mt: rem(10),
              }}
            >
              <Themed.h6
                sx={{
                  my: 0,
                  mr: rem(20),
                }}
              >
                Qty
              </Themed.h6>
              <input
                sx={{
                  border: "3px solid",
                  borderColor: "grey",
                  py: 1,
                  px: 1,
                  fontSize: 1,
                  textAlign: "center",
                  width: rem(40),
                  fontFamily: "body",
                  borderRadius: 0,
                  color: "darkPurple",
                }}
                type="text"
                value={quantityValue}
                onChange={e => {
                  onQuantityChange({
                    productId: item.variant.product.id,
                    id: item.variant.id,
                    quantity: e.target.value,
                  })
                }
                }
                onBlur={onQuantityBlur}
              />
            </div>
          </div>
        </div>

        <Themed.h6
          sx={{
            display: ["none", "block"],
            mt: 1,
            minWidth: rem(54),
            textAlign: "right",
          }}
        >
          ${(item.variant.price.amount * item.quantity).toFixed(2)}
        </Themed.h6>
      </div>
    </div>
  )
}

export default LineItem
