/** @jsx jsx */
import { jsx } from "theme-ui"
import { Themed } from "@theme-ui/mdx"
import { useState } from "react"
import { connect } from "react-redux"
import { Link } from "gatsby"
import { Box } from "@theme-ui/components"
import styled from "styled-components"
import theme, { rem } from "@src/theme"
import Button from "@components/Button"
import Logo from "@components/assets/svg/logo.svg"
import CarrotDown from "@components/assets/svg/carrot-down.svg"
import Bag from "@components/assets/svg/bag.svg"
import FullBag from "@components/assets/svg/full-bag.svg"
import CartModal from "@shop/components/CartModal"
import Hamburger from "@components/Hamburger"

const DropdownNavItem = ({ children, links }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleKeyDown = e => {
    if (e.key === "Enter" || e.keyCode === 13) {
      if (isOpen) {
        setIsOpen(false)
      }
      setIsOpen(true)
    }
  }
  return (
    <Dropdown
      tabIndex="0"
      onKeyDown={handleKeyDown}
      onBlur={() => setIsOpen(false)}
      sx={{
        outline: "none",
        display: ["none", "none", null, "flex"],
        "&:hover, &:active ": {
          color: "purple",
          svg: {
            color: "currentColor",
            transform: "translate(8px, -4px) rotate(180deg)",
            transition: "transform 250ms ease",
          },
        },
      }}
    >

      <Themed.h6
        sx={{
          m: 0,
          pr: [rem(18), null, null, null, rem(40)],
          alignItems: "center",
          cursor: "pointer",
          fontSize: `${rem(16)}!important`,
          '@media screen and (min-width: 1020px) and (max-width: 1100px)': {
            fontSize: `15px!important`, // Change background on tablets
          },
          // 'position': 'relative',

        }}
      >
        <span> {children}</span>
        <CarrotDown
          sx={{
            transform: "translate(5px, -4px)",
            color: "currentColor",
            fill: "currentColor",
            // position: 'absolute',
            // right: rem(8),
            // top: rem(4)

          }}
        />
      </Themed.h6>

      <div
        className={`dropdown-content ${isOpen ? `is-open` : ``}`}
        sx={{
          pt: 4,
          pb: 2,
          left: "-1.33rem",
          top: rem(17),
        }}
      >
        {links.map(({ label, link }) => (
          <Link
            to={link}
            key={link}
            tabIndex={isOpen || "0"}
            sx={{
              pb: 3,
              px: 3,
              "&:last-child": {
                pb: 1,
              },
            }}
          >
            <Themed.h6
              sx={{
                m: 0,
                textDecoration: "underline",
                color: "darkPurple",
                lineHeight: rem(24),
                whiteSpace: "nowrap",
                fontSize: `${rem(18)}!important`,
                width: "100%",
              }}
            // activeStyle={{ color: theme.colors.purple }}
            >
              {label}
            </Themed.h6>
          </Link>
        ))}
      </div>
    </Dropdown>
  )
}

const NavLabel = ({ link, label }) => (
  <Themed.h6
    as="li"
    sx={{
      m: 0,
      display: ["none", "none", "none", "block"],
      fontSize: `${rem(16)}!important`,
      pr: [rem(18), null, null, null, rem(40)],
      "&:hover": {
        color: "purple",
      },
      '@media screen and (min-width: 1020px) and (max-width: 1100px)': {
        fontSize: `15px!important`, // Change background on tablets
      },

    }}
  >
    <Link
      to={link}
      className="unstyled-link"
      activeStyle={{ color: theme.colors.purple }}
    >
      {label}
    </Link>
  </Themed.h6>
)

const Dropdown = styled.li`
  position: relative;

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    z-index: 1;
  }

  .dropdown-content a {
    text-align: left;
  }

  &:hover .dropdown-content,
  .dropdown-content.is-open {
    display: flex;
    flex-direction: column;
    align-items: baseline;
  }
`

const Nav = ({
  closeCartModal,
  openCartModal,
  cartModalOpen,
  checkout,
  openDonationModal,
  navOpen,
  toggleNav,
}) => {
  return (
    <nav
      sx={{
        py: 2,
        position: "relative",
        zIndex: 9,
      }}
      id="nav1"
    >
      <div
        className="p-left"
        sx={{
          px: [rem(12), null, "2.5vw"],
        }}
      >
        <Box
          sx={{
            listStyleType: "none",
            display: "flex",
            justifyContent: "space-between",
          }}
          columns={[3]}
        >
          <Box
            sx={{
              display: ["block", "block", "block", "none"],
              alignSelf: "center",
              width: "90px",
            }}
          >
            <Hamburger />
          </Box>
          <Box
            sx={{
              display: ["none", "none", "none", "flex"],
              alignItems: "center",
            }}
          >
            <DropdownNavItem
              links={[
                { label: "about us", link: "/about" },
                { label: "young leaders", link: "/young-leaders" },
                { label: "our team", link: "/our-team" },
                { label: "governance", link: "/governance" },
              ]}
            >
              About
            </DropdownNavItem>
            <DropdownNavItem
              links={[
                { label: "donate", link: "/donate" },
                { label: "join us", link: "/join-us" },
                { label: "Partner", link: "/partners" },
                { label: "campaigns", link: "/campaigns" },
                { label: "fundraise", link: "/fundraise" },
                { label: "resources", link: "/resources" },
              ]}
            >
              Get involved
            </DropdownNavItem>
            <DropdownNavItem
              links={[
                { label: "upcoming", link: "/events" },
                { label: "queer formal", link: "/events/queer-formal" },
                { label: "discord", link: "/events/discord" },
              ]}
            >
              Events
            </DropdownNavItem>

            <DropdownNavItem
              links={[
                { label: "Schools", link: "/workshops/schools" },
                { label: "Workplaces", link: "/workshops/workplace" },
                { label: "eLearning", link: "/elearning" },
              ]}
            >
              Training
            </DropdownNavItem>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Link
              to="/"
              className="unstyled-link"
              sx={{
                transform: "translateY(6px)",
              }}
            >
              <Logo
                sx={{
                  width: [rem(95), null, rem(120)],
                  height: [rem(38)],
                }}
              />
            </Link>
          </Box>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              minWidth: "90px",
              justifyContent: "flex-end",
            }}
          >
            <NavLabel label="Articles" link="/articles" />
            <NavLabel label="Youth directory" link="/youth-directory" />
            <NavLabel label="Shop" link="/shop" />
            <Box
              as="li"
              tabIndex="0"
              sx={{
                pr: [rem(18), null, null, null, rem(20)],
                display: ["none", null, "block", null],
                position: "relative",
                zIndex: 888,
              }}
            >
              <Button onClick={openDonationModal} styles={{
                fontSize: rem(18),
                px: rem(18),
                py: rem(14),
              }}>Donate</Button>
            </Box>
            <Box
              as="li"
              sx={{
                transform: "translateY(3px)",
                display: "flex",
              }}
              onClick={() => {
                cartModalOpen ? closeCartModal() : openCartModal()
              }}
            >
              <button
                sx={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  if (navOpen) {
                    toggleNav()
                  }
                  return cartModalOpen ? closeCartModal() : openCartModal()
                }}
              >
                {checkout && checkout.lineItems.length ? (
                  <div sx={{ position: "relative" }}>
                    <FullBag
                      sx={{
                        width: [rem(20), null],
                      }}
                    />
                    <div
                      sx={{
                        position: "absolute",
                        bg: "darkPurple",
                        color: "white",
                        minWidth: "16px",
                        height: "16px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "50%",
                        top: "1px",
                        right: "-6px",
                        fontSize: "10px",
                      }}
                    >
                      {checkout.lineItems.length}
                    </div>
                  </div>
                ) : (
                  <Bag
                    sx={{
                      width: [rem(20), null],
                      "&:hover": {
                        color: "purple",
                      },
                    }}
                  />
                )}
              </button>
            </Box>
          </Box>
        </Box>
      </div>

      {cartModalOpen && (
        <CartModalOuter
          sx={{
            position: "absolute",
            bg: "white",
            right: 0,
          }}
        >
          <div
            sx={{
              p: [4, null, 6],
              bg: "white",
            }}
          >
            <CartModal onClose={closeCartModal} />
          </div>
        </CartModalOuter>
      )}
    </nav>
  )
}

export default connect(
  state => ({
    cartModalOpen: state.cartModal.cartModalOpen,
    checkout: state.cart.checkout,
    navOpen: state.ui.navOpen,
  }),
  dispatch => ({
    closeCartModal: () => dispatch.cartModal.toggleCartModal(false),
    openCartModal: () => dispatch.cartModal.toggleCartModal(true),
    openDonationModal: () => dispatch.donationModal.toggleDonationModal(true),
    toggleNav: () => dispatch.ui.toggleNav(),
  })
)(Nav)

const CartModalOuter = styled.div`
  /* This way it could be display flex or grid or whatever also. */
  display: block;
  /* Probably need media queries here */
  width: 600px;
  max-width: 90%;
  background: white;
`
