/** @jsx jsx */
import { jsx, Styled } from "theme-ui"
import React from "react"
import { useState, useEffect, useRef } from "react"
import Pluralize from "pluralize"
import { connect } from "react-redux"
import { client } from "@shop/helpers"
import LineItem from "@shop/components/LineItem"
import { rem } from "@src/theme"
import Button from "@components/Button"
import IconLink from "@components/IconLink"
import Cross from "@components/assets/svg/cross.svg"
import { Themed } from "@theme-ui/mdx"
import { getNewCheckoutPromise } from "@shop/helpers"
import useMetaPixel from "@src/utils/useMetaPixel"

const CartModal = ({
  checkout,
  removeLineItem,
  closeCartModal,
  onClose,
  ...props
}) => {
  if (checkout === null || checkout === undefined) {
    getNewCheckoutPromise().then(value => {
      props.setCheckout(value)
    })
    return null
  }
  const [items, setItems] = useState(checkout ? checkout.lineItems : [])
  const { pushEvent } = useMetaPixel()
  const wrapperRef = useRef(null)
  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        closeCartModal()
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside)

    document.onkeydown = e => {
      if (e.key === "Escape" || e.keyCode === 27) {
        onClose()
      }
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside)

      document.onkeydown = () => { }
    }
  }, [wrapperRef])

  // useEffect(() => {
  //   console.log('Fetching Checkout')
  //   fetchCheckout(props.checkoutId)
  // }, [props.checkoutId])

  // useEffect(() => {
  //   console.log('Checkout Updated', checkout)
  //   if (checkout.orderStatusUrl !== null) {
  //     console.log('Checkout Complete')
  //     props.resetCheckout(null)
  //   }
  // }, [checkout, props.resetCheckout])

  const handleCheckout = async () => {
    closeCartModal()
    pushEvent({ eventCode: "InitiateCheckout" })
    window.open(checkout.webUrl)
  }

  function adjustQuantity(id, productId, array, maximum) {
    // Calculate the sum of 'quantity' values excluding the object with the given 'id'
    const sum = array.reduce((acc, obj) => {
      if (obj.id !== id && obj.variant.product.id == productId) {
        return acc + Number(obj.quantity)
      }
      return acc
    }, 0)

    // Find the object with the given 'id'
    const object = array.find(obj => obj.variant.id == id)

    // Calculate the maximum allowed quantity without exceeding the maximum value
    const maxAllowedQuantity = Math.min(
      maximum - (sum - object.quantity),
      object.quantity
    )

    // Adjust the 'quantity' property of the object
    object.quantity = maxAllowedQuantity

    return array
  }

  const handleQuantityChange = ({ id, quantity, productId }) => {
    let allVariants = checkout?.lineItems
      ? checkout?.lineItems.filter(i => i?.variant?.product?.id == productId)
      : []
    let totalQty = 0
    const limit = props.itemLimits.find(i => i.id === productId).limit

    if (allVariants.length > 1) {
      totalQty = allVariants.reduce(
        (sum, obj) => sum + (obj ? obj.quantity : 0),
        0
      )
      const currQty = items.find(i => i.variant.id === id)
        ? items.find(i => i.variant.id === id).quantity
        : null

      const difference =
        quantity > currQty && Math.abs(quantity - currQty) >= 1
          ? Math.abs(quantity - currQty)
          : null

      if (totalQty + difference > limit) {
        const updatedQty = adjustQuantity(
          id,
          productId,
          items.map(item =>
            item.variant.id === id ? { ...item, quantity } : item
          ),
          limit
        )

        setItems(updatedQty)

        return
      }
    } else if (quantity > limit) {
      return
    }

    setItems(
      items.map(item =>
        item.variant.id === id ? { ...item, quantity: Number(quantity) } : item
      )
    )
  }

  const handleLineItemRemove = (id, variantId, productId) => {
    setItems(items.filter(i => i.id !== id))
    removeLineItem({ id, variantId, productId })
  }

  const handleQuantityBlur = async () => {
    const res = await client.checkout.updateLineItems(
      props.checkoutId,
      items.map(item => ({
        id: item.id,
        quantity: parseInt(item.quantity, 10),
      }))
    )

    props.setCheckout(res)
  }

  const clearBag = async () => {
    const fetchedCheckout = await fetchCheckout(props.checkoutId)

    if (!fetchedCheckout) {
      props.resetCheckout()
      return
    }

    const res = await client.checkout.removeLineItems(
      props.checkoutId,
      items.map(i => i.id)
    )

    setItems([])

    props.resetCheckout(res)
    props.resetItemLimits()
  }

  const fetchCheckout = async checkoutId => {
    const res = await client.checkout.fetch(checkoutId)
    return res
  }

  const lineItems = items.map(item => {
    let allVariants = checkout?.lineItems
      ? checkout?.lineItems.filter(
        i => i?.variant?.product?.id == item?.variant?.product?.id
      )
      : []
    let totalQty = 0
    if (allVariants.length) {
      totalQty = allVariants.reduce((sum, obj) => sum + obj.quantity, 0)
    }

    return (
      <LineItem
        key={item.id}
        item={item}
        onRemove={handleLineItemRemove}
        onQuantityChange={handleQuantityChange}
        quantityValue={item.quantity}
        onQuantityBlur={handleQuantityBlur}
        limit={
          props.itemLimits.find(obj => obj.id == item?.variant?.product?.id)
            ?.limit
        }
        totalQty={totalQty}
      />
    )
  })

  const isAnyLineItems = lineItems.length > 0

  return (
    <div ref={wrapperRef}>
      <div
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 3,
        }}
      >
        <Themed.h4 sx={{ my: 0 }}>
          {isAnyLineItems && (
            <React.Fragment>
              <span
                sx={{
                  color: "purple",
                }}
              >
                {lineItems.length}{" "}
              </span>
              {Pluralize("item", lineItems.length)} in your bag!
            </React.Fragment>
          )}
        </Themed.h4>
        <button
          sx={{
            cursor: "pointer",
          }}
        >
          <Cross
            onClick={() => {
              closeCartModal()
              onClose()
            }}
          />
        </button>
      </div>
      {isAnyLineItems && (
        <React.Fragment>
          <div
            sx={{
              borderBottom: `2px solid rgba(58, 42, 85, 0.3)`,
            }}
          >
            <div
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div
                sx={{
                  display: "flex",
                  width: [rem(200), null, rem(280)],
                }}
              >
                <Themed.h5>Item</Themed.h5>
              </div>
              <Themed.h5 sx={{ fontSize: rem(18), display: ["none", "block"] }}>
                Price
              </Themed.h5>
            </div>
          </div>

          <div>{lineItems}</div>

          <div
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <div sx={{ my: rem(20), display: "flex", alignItems: "center" }}>
              <Themed.h5 sx={{ mr: 5, my: 0, fontSize: rem(18) }}>
                Total
              </Themed.h5>{" "}
              <p sx={{ my: 0, transform: "translateY(-3px)" }}>
                ${checkout.totalPrice.amount}
              </p>
            </div>
            <div
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <button
                sx={{
                  variant: "typography.link",
                  textDecoration: "underline",
                  mr: 3,
                  cursor: "pointer",
                }}
                onClick={clearBag}
              >
                Clear bag
              </button>

              <Button onClick={handleCheckout} bg="darkPurple" color="white">
                Checkout
              </Button>
            </div>
          </div>
        </React.Fragment>
      )}

      {!isAnyLineItems && (
        <div>
          <Themed.h4
            sx={{
              textAlign: "center",
            }}
          >
            Your cart is empty
          </Themed.h4>
          <div
            sx={{
              display: "flex",
              justifyContent: "space-around",
              flexDirection: ["column", "row"],
            }}
          >
            <IconLink
              link={"/shop/pride"}
              label="Shop pride"
              type="arrowRight"
              onClick={closeCartModal}
            />
            <IconLink
              link={"/shop/school"}
              label="Shop school"
              type="arrowRight"
              onClick={closeCartModal}
            />
            <IconLink
              link={"/shop/workplace"}
              label="Shop workplace"
              type="arrowRight"
              onClick={closeCartModal}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default connect(
  state => ({
    checkout: state.cart.checkout,
    checkoutId: state.cart.checkoutId,
    itemLimits: state.cart.itemLimits,
  }),
  dispatch => ({
    addVariantToCart: dispatch.cart.addVariantToCart,
    removeLineItem: dispatch.cart.removeLineItem,
    closeProductModal: () => dispatch.cartModal.toggleProductModal(false),
    closeCartModal: () => dispatch.cartModal.toggleCartModal(false),
    setCheckout: dispatch.cart.setCheckout,
    resetCheckout: dispatch.cart.resetCheckout,
    resetItemLimits: dispatch.cart.resetItemLimits,
  })
)(CartModal)
