import "babel-polyfill"
import "normalize.css"

import browserWrapper from "@src/rootBrowser"
import pageWrapper from "@src"

export const wrapRootElement = browserWrapper
export const wrapPageElement = pageWrapper

export const onRouteUpdate = ({ location, prevLocation }) => {
    // Check if our form tracking function exists and call it
    if (typeof window !== 'undefined' && window.___checkFormStarted) {
        window.___checkFormStarted()
    }
}