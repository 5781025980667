/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect } from "react"
import Link from "@components/Link"
import { connect } from "react-redux"
import { navigate } from "@reach/router"
import styled from "styled-components"
import FocusLock from "react-focus-lock"

import Nav from "@components/Nav"
import theme, { rem } from "@src/theme"
import ButtonLink from "@components/ButtonLink"
import useWindowSize from "@helpers/hooks/useWindowSize"
import {
  Accordion as LibraryAccordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion"
import "react-accessible-accordion/dist/fancy-example.css"

const Panel = ({ title, url, children, onClose }) => {
  return (
    <PanelStyleWrapper
      hasChildren={children && children.length > 0 ? true : false}
    >
      <AccordionItem
        uuid={children && children.length ? `has-children-${title}` : title}
      >
        <AccordionItemHeading>
          <AccordionItemButton>{title}</AccordionItemButton>
        </AccordionItemHeading>
        {children ? (
          <AccordionItemPanel>
            <ul
              className="nav-links-list"
              sx={{
                listStyleType: "none",
              }}
            >
              {children.map(({ title, url }) => (
                <li key={title}>
                  <Link
                    to={url}
                    style={{
                      fontSize: `${rem(18)}!important`,
                      width: "100%",
                      display: "block",
                      pl: rem(22),
                    }}
                    onClick={onClose}
                  >
                    {title}
                  </Link>
                </li>
              ))}
            </ul>
          </AccordionItemPanel>
        ) : null}
      </AccordionItem>
    </PanelStyleWrapper>
  )
}

const Accordion = ({ onClose }) => {
  const onChangeHandler = panel => {
    if (panel && panel[0]) {
      if (!panel[0].includes("has-children")) {
        const url = navObjects.filter(({ title }) => title === panel[0])[0].url
        onClose()
        navigate(url)
      }
    }
  }
  return (
    <AccordionStylesWrapper>
      <LibraryAccordion allowZeroExpanded onChange={onChangeHandler}>
        {navObjects.map((item, index) => (
          <Panel {...item} onClose={onClose} key={index} />
        ))}
      </LibraryAccordion>
    </AccordionStylesWrapper>
  )
}

const navObjects = [
  {
    title: "Shop",
    url: "/shop",
  },
  {
    title: "About",
    url: "",
    children: [
      { title: "About us", url: "/about" },
      { title: "Young leaders", url: "/young-leaders" },
      { title: "Our team", url: "/our-team" },
      { title: "Governance", url: "/governance" },
    ],
  },
  {
    title: "Get involved",
    url: "",
    children: [
      { title: "Donate", url: "/donate" },
      { title: "Join us", url: "/join-us" },
      { title: "Partner", url: "/partners" },
      { title: "Campaigns", url: "/campaigns" },
      { title: "Fundraise", url: "/fundraise" },
      { title: "Resources", url: "/resources" },
    ],
  },
  {
    title: "Events",
    url: "",
    children: [
      { title: "upcoming events", url: "/events" },
      { title: "queer formal", url: "/events/queer-formal" },
      { title: "discord", url: "/events/discord" },
    ]
  },
  {
    title: "Training",
    url: "",
    children: [
      {
        title: "Schools",
        url: "/workshops/schools",
      },
      {
        title: "Workplaces",
        url: "/workshops/workplace",
      },
      {
        title: "eLearning library",
        url: "/elearning",
      },
    ],
  },
  {
    title: "Articles",
    url: "/articles",
  },
  {
    title: "Youth directory",
    url: "/youth-directory",
  },
]

const MobileNav = ({ navOpen, closeNav, openDonationModal }) => {
  const windowSize = useWindowSize()
  useEffect(() => {
    if (windowSize.width >= 1200 && navOpen) {
      closeNav()
    }
  }, [windowSize])
  return navOpen ? (
    <div
      sx={{
        position: "fixed",
        left: "6px",
        top: "6px",
        height: "calc(100vh - 12px)",
        width: "calc(100% - 12px)",
        zIndex: "11",
        backgroundColor: "white",
        overflow: "auto",
      }}
    >
      <FocusLock>
        <Nav />
        <Accordion onClose={() => closeNav()} />
        <div
          sx={{
            px: ["4vw", "2.5vw"],
            pb: 4,
          }}
        >
          <ButtonLink
            styles={{ width: "100%", mt: rem(20) }}
            to="https://www.givenow.com.au/minus18"
            external
          >
            Donate
          </ButtonLink>
        </div>
      </FocusLock>
    </div>
  ) : null
}

export default connect(
  state => ({
    navOpen: state.ui.navOpen,
  }),
  dispatch => ({
    closeNav: dispatch.ui.closeNav,
    openDonationModal: () => dispatch.donationModal.toggleDonationModal(true),
  })
)(MobileNav)

const AccordionStylesWrapper = styled.div`
  font-size: ${rem(18)};

  .accordion {
    border: none;
  }
  .accordion__button {
    font-family: ${theme.fonts.heading};
    color: ${theme.colors.darkPurple};
    background: ${theme.colors.white};
    font-weight: 900;

    &:hover {
      background-color: transparent;
    }
  }
  .accordion__button[aria-expanded="true"] {
    color: ${theme.colors.purple};
  }
  .accordion__button:after {
    display: inline-block;
    content: "";
    height: 10px;
    width: 10px;
    margin-right: 12px;
    border-bottom: 3px solid ${theme.colors.darkPurple};
    border-right: 3px solid ${theme.colors.darkPurple};
    transform: rotate(-45deg) translate(-4px, 10px);
    text-align: right;
    float: right;
    transition: transform 300ms ease;
  }

  .accordion__button[aria-expanded="true"]::after,
  .accordion__button[aria-selected="true"]::after {
    transform: rotate(45deg) translate(-4px, 10px);
    border-color: ${theme.colors.purple};
  }

  .accordion__button:before {
    display: none;
  }
`

const PanelStyleWrapper = styled.div`
  .accordion__button {
    text-transform: uppercase;
    padding-top: ${rem(20)}!important;
    padding-bottom: ${rem(20)}!important;
    @media screen and (max-height: 850px) {
      padding-top: ${rem(15)}!important;
      padding-bottom: ${rem(15)}!important;
    }

    @media screen and (max-height: 700px) {
      padding-top: ${rem(12)}!important;
      padding-bottom: ${rem(12)}!important;
    }

    @media only screen and (max-width: ${theme.breakpoints[0]}) {
      padding-left: 4vw;
      padding-right: 4vw;
    }
    @media only screen and (min-width: ${theme.breakpoints[0]}) {
      padding-left: 2.5vw;
      padding-right: 2.5vw;
    }
  }

  .accordion__button:after {
    display: ${p => (p.hasChildren ? "inline-block" : "none")};
  }

  .accordion__panel {
    @media only screen and (max-width: ${theme.breakpoints[0]}) {
      padding: 0 4vw 0 4vw;
    }
    @media only screen and (min-width: ${theme.breakpoints[0]}) {
      padding: 0 2.5vw 0 2.5vw;
    }
  }

  .nav-links-list {
    li {
      margin-bottom: ${rem(20)};
    }
  }
`
